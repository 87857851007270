import React from "react";
import "./ReviewSentiment.css";

export const ReviewSentiment = () => {
  return (
    <div className="reviewsentiment">
        <h3>Customers Say</h3>
        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
        libero laboriosam, aut, minus quis odio recusandae quaerat ut,
        voluptatum facere dolorem! Ex blanditiis necessitatibus eum ea
        sit, natus accusantium eaque?
        </p>
    </div>
  );
};
